<template>
  <component>
    <b-overlay :show="show" rounded="sm">
    <b-card v-if="session > 0">
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="PlusCircleIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Input Pengajuan Diskon Khusus <span>{{ (trn_code == null || trn_code == "") ? "" : ": "+trn_code }}</span>
        </h4>
      </div><br>
      <div>
        <input type="hidden" name="trn_code" class="form-control" :value="trn_code">
        <input type="hidden" name="trn_dtl_sisa_kuota_hide" class="form-control" :value="trn_dtl_sisa_kuota_hide"></input>
        <input type="hidden" name="trn_dtl_pricelist_hide" class="form-control" :value="trn_dtl_pricelist_hide"></input>
        <input type="hidden" name="trn_dtl_sellprice_hide" class="form-control" :value="trn_dtl_sellprice_hide"></input>
        <input type="hidden" name="trn_dtl_sellprice_req_hide" class="form-control" :value="trn_dtl_sellprice_req_hide"></input>
        <form-wizard
          color="#ff8f00"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Send Approval"
          back-button-text="Previous"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <!-- step 1 -->
          <tab-content
            title="Step 1"
            :before-change="validationForm"
          >
            <validation-observer
              ref="step1"
              tag="form"
            >
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Jenis Input"
                    rules="required"
                  >
                    <b-form-group
                      label="Jenis Input"
                      label-for="jenisinput"
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="jenisinput"
                        v-model="userDataInfo.trn_type"
                        placeholder="Pilih Jenis Input"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="trn_type_opt"
                        label="text"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="domain"
                    rules="required"
                  >
                    <b-form-group
                      label="Bisnis Unit"
                      label-for="domain"
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="domain"
                        v-model="userDataInfo.trn_bu"
                        placeholder="Pilih Domain"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="trn_bu_opt[0]"
                        label="text"
                        @input="onchangeBU()"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="divisi"
                    rules="required"
                  >
                    <b-form-group
                      label="Divisi"
                      label-for="divisi"
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="divisi"
                        v-model="userDataInfo.trn_div"
                        placeholder="Pilih Divisi"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="trn_div_opt[0]"
                        ref='trn_div_ref'
                        label="text"
                        @input="onchangeDiv()"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="segmen"
                    rules="required"
                  >
                    <b-form-group
                      label="Segmen"
                      label-for="segmen"
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="segmen"
                        v-model="userDataInfo.trn_segmen"
                        placeholder="Pilih Segmen"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="trn_segmen_opt[0]"
                        label="text"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Nama Proyek"
                    label-for="nama-proyek"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama Proyek"
                      rules="required"
                    >
                      <b-form-input
                        id="nama-proyek"
                        v-model="userDataInfo.trn_name_project"
                        placeholder="Input Nama Proyek"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Dasar Hitung"
                    rules="required"
                  >
                    <b-form-group
                      label="Dasar Hitung"
                      label-for="dasar-hitung"
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="dasar-hitung"
                        v-model="userDataInfo.trn_dasarhitung"
                        placeholder="Pilih Dasar Hitung"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="dasarName"
                        label="text"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Diskon diminta (%)"
                    label-for="diskon"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Diskon"
                      rules="required"
                    >
                      <b-form-input
                        id="diskon"
                        type="number"
                        v-model="userDataInfo.trn_diskon_request"
                        placeholder="Input Diskon diminta (%)"
                        :state="errors.length > 0 ? false:null"
                      />
                      <!-- <cleave
                        id="diskon"
                        v-model="userDataInfo.trn_diskon_request"
                        class="form-control"
                        :raw="false"
                        :options="formatter.number"
                        placeholder="Input Diskon diminta (%)"
                        :state="errors.length > 0 ? false:null"
                      /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal Pengajuan"
                    rules="required"
                  >
                    <b-form-group
                      label="Tanggal Pengajuan"
                      label-for="tgl-pengajuan"
                      :state="errors.length > 0 ? false:null"
                    >
                    <flat-pickr
                        v-model="userDataInfo.trn_date_request"
                        id="tgl_pengajuan"
                        class="form-control"
                        placeholder="YYYY-MM-DD"
                        :state="errors.length > 0 ? false:null"
                      />                    
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Periode Delivery"
                    label-for="periode-delivery"
                  >
                    <b-row>
                      <b-col md="6">
                        <validation-provider
                          #default="{ errors }"
                          name="Start Date"
                          rules="required"
                        >
                        <flat-pickr
                            v-model="userDataInfo.trn_periode_start"
                            id="start_date"
                            class="form-control"
                            placeholder="YYYY-MM-DD"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>

                      <b-col md="6">
                        <validation-provider
                          #default="{ errors }"
                          name="End Date"
                          rules="required"
                        >
                        <flat-pickr
                            v-model="userDataInfo.trn_periode_end"
                            id="end_date"
                            class="form-control"
                            placeholder="YYYY-MM-DD"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Mitra Usaha"
                    rules="required"
                  >
                    <b-form-group
                      label="Mitra Usaha"
                      label-for="trn_mitrausaha"
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="trn_mitrausaha"
                        v-model="userDataInfo.trn_mitrausaha"
                        placeholder="Pilih Mitra Usaha"
                        ref='drop_trn_mitrausaha'
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="trn_mitrausaha_opt[0]"
                        label="label"
                        @input="onchangeMU()"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                    <b-form-group
                      label="Program DF"
                      label-for="program-df"
                    >
                      <v-select
                        id="program-df"
                        v-model="userDataInfo.trn_program_df"
                        placeholder="Pilih Program DF"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="trn_program_df_opt"
                        label="text"
                        disabled
                      />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Premi"
                    rules="required"
                  >
                    <b-form-group
                      label="Premi"
                      label-for="premi"
                      :state="errors.length > 0 ? false:null"
                    > 
                      <v-select
                        id="premi"
                        v-model="userDataInfo.trn_premi"
                        placeholder="Pilih Premi"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="premiName"
                        label="text"
                        @input="onchangePremi()"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col 
                  md="6"
                  v-if="userDataInfo.trn_premi.value == 1"
                >
                  <b-form-group
                    label="Presentase (%)"
                    label-for="presentase"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Presentase"
                      rules="required"
                    >
                      <b-form-input
                        id="presentase"
                        v-model="userDataInfo.trn_premi_persen"
                        placeholder="Premi (%)"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Catatan"
                    label-for="catatan"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Catatn"
                      rules="required"
                    >
                      <b-form-input
                        id="catatan"
                        v-model="userDataInfo.trn_notes"
                        placeholder="Input Catatan"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- step 2 -->
          <tab-content
            title="Step 2"
            :before-change="validationFormInfo"
          >
            <validation-observer
              ref="step2"
              tag="form"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="No. ACR"
                    label-for="acr"
                  >
                      <b-form-input
                        id="acr"
                        v-model="trn_dtl_no_acr_input"
                        placeholder="Input No ACR"
                        :disabled="userDataInfo.trn_type.value ==='baru'"
                        @keyup.enter="changeNoACR()"
                      />
                      <small class="text-warning">Jika jenis input yang dipilih Sisa Kuota no ACR wajib diisi dengan no. ACR sebelumnya.</small>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Kode Group"
                    rules="required"
                  >
                    <b-form-group
                      label="Kode Group"
                      label-for="kode-group"
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="kode-group"
                        v-model="trn_dtl_group_kode"
                        ref="drop_trn_dtl_group_kode"
                        placeholder="Pilih Kode Group"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="trn_dtl_group_kode_opt[0]"
                        label="text"
                        @input="onchangeKodeGroup()"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Diskon diminta (%)"
                    label-for="diskon1"
                  >
                      <b-form-input
                        id="diskon1"
                        v-model="userDataInfo.trn_diskon_request"
                        placeholder="Input Diskon diminta (%)"
                        disabled
                      />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Sisa Kuota"
                    label-for="sisa-kuota"
                  >
                      <!-- <b-form-input
                        id="sisa-kuota"
                        v-model="trn_dtl_sisa_kuota"
                        placeholder="Input Sisa Kuota"
                        disabled
                      /> -->
                      <cleave
                        id="sisa-kuota"
                        v-model="trn_dtl_sisa_kuota"
                        class="form-control"
                        :raw="false"
                        :options="formatter.number"
                        placeholder="Sisa Kuota"
                        disabled
                      />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Volume (Ton)"
                    :rules="{required:true}"
                  >
                    <b-form-group
                      label="Volume (Ton)"
                      label-for="volume"
                    >
                      <b-form-input
                        id="volume"
                        v-model="trn_dtl_volume"
                        placeholder="Input Volume (Ton)"
                        :disabled="isDisabled"
                        @keyup="changeVolume()"
                      />
                    </b-form-group>
                  </validation-provider> -->
                  <b-form-group
                      label="Volume (Ton)"
                      label-for="volume"
                    >
                  <validation-provider
                      #default="{ errors }"
                      name="Volume (Ton)"
                      :rules="{required: isRequiredVolume}"
                    >
                      <b-form-input
                        id="volume"
                        type="number"
                        v-model="trn_dtl_volume"
                        placeholder="Input Volume (Ton)"
                        @keyup="changeVolume()"
                        :disabled="isDisabled"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <!-- <b-form-group
                    label="Pricelist (Rp)"
                    label-for="pricelist"
                  >
                      <cleave
                        id="pricelist"
                        v-model="trn_dtl_pricelist"
                        raw="false"
                        placeholder="Input Pricelist (Rp)"
                        :options="formatter.number"
                        :disabled="isDisabledPL"
                      />
                  </b-form-group> -->
                  <b-form-group
                    label="Pricelist (Rp)"
                    label-for="pricelist"
                  >
                    <cleave
                      id="pricelist"
                      v-model="trn_dtl_pricelist"
                      class="form-control"
                      :raw="false"
                      :options="formatter.number_pl"
                      placeholder="Input Pricelist (Rp)"
                      :disabled="isDisabledPL"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Diskon Standart (%)"
                    label-for="diskon-standar"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Diskon Standart (%)"
                      rules="required"
                    >
                      <b-form-input
                        id="diskon-standar"
                        type="number"
                        v-model="trn_dtl_diskonstandart"
                        placeholder="Input Diskon Standart (%)"
                        :state="errors.length > 0 ? false:null"
                        @keyup="changeDiskonStd()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Harga Jual Standart"
                    label-for="harga-jual"
                  >
                      <!-- <b-form-input
                        id="harga-jual"
                        v-model="trn_dtl_sellprice"
                        placeholder="Input Harga Jual Standart"
                        disabled
                      /> -->
                      <cleave
                        id="harga-jual"
                        v-model="trn_dtl_sellprice"
                        class="form-control"
                        :raw="false"
                        :options="formatter.number"
                        placeholder="Harga Jual Standart (Rp)"
                        disabled
                      />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Harga Jual diminta"
                    label-for="hargadiminta"
                  >
                      <!-- <b-form-input
                        id="hargadiminta"
                        v-model="hargadiminta"
                        placeholder="Input Harga Jual diminta"
                        disabled
                      /> -->
                      <cleave
                        id="hargadiminta"
                        v-model="trn_dtl_sellprice_req"
                        class="form-control"
                        :raw="false"
                        :options="formatter.number"
                        placeholder="Harga Jual Diminta (Rp)"
                        disabled
                      />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Selisih Harga"
                    label-for="selisih-harga"
                  >
                      <!-- <b-form-input
                        id="selisih-harga"
                        v-model="selisihharga"
                        placeholder="Input Selisih Harga"
                        disabled
                      /> -->
                      <cleave
                        id="selisih-harga"
                        v-model="trn_dtl_selisih_harga"
                        class="form-control"
                        :raw="false"
                        :options="formatter.number"
                        placeholder="Selisih Harga (Rp)"
                        disabled
                      />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Keterangan Khusus"
                    label-for="keterangan-khusus"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Keterangan Khusus"
                      :rules="{required: true}"
                    >
                      <b-form-input
                        id="keterangan-khusus"
                        v-model="trn_dtl_desc"
                        placeholder="Input Keterangan Khusus"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Tujuan Kirim"
                    rules="required"
                  >
                    <b-form-group
                      label="Tujuan Kirim"
                      label-for="tujuan-kirim"
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="tujuan-kirim"
                        v-model="trn_dtl_shipto"
                        ref="drop_trn_dtl_shipto"
                        placeholder="Pilih Nama Tujuan Kirim"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="trn_dtl_shipto_opt[0]"
                        label="text"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Franco"
                    rules="required"
                  >
                    <b-form-group
                      label="Franco"
                      label-for="franco"
                      :state="errors.length > 0 ? false:null"
                    >
                      <v-select
                        id="franco"
                        v-model="trn_dtl_franco"
                        placeholder="Pilih Franco"
                        ref="drop_trn_dtl_franco"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="francoName"
                        label="text"
                        @input="onchangeFranco()"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Keterangan Franco"
                    label-for="ketfranco"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Keterangan Franco"
                      :rules="{required: isRequiredKeteranganFranco}"
                    >
                      <b-form-input
                        id="ketfranco"
                        v-model="trn_dtl_franco_desc"
                        placeholder="Input Keterangan Franco"
                        :disabled="isDisabledFranco"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
          
                <b-button
                    variant="primary"
                    @click.prevent="validationFormDetail"
                  >
                    <span class="text-nowrap">Simpan</span>
                  </b-button>

                  <br>
                  <br>

              <b-row>
                <b-table-simple
                  hover
                  caption-top
                  responsive
                  class="rounded-bottom mb-0"
                >
                  <b-thead head-variant="light">
                    <b-tr>
                      <b-th>No</b-th>
                      <b-th>No.ACR</b-th>
                      <b-th>Sisa Kuota</b-th>
                      <b-th>Nama Product</b-th>
                      <b-th>Volume (Ton)</b-th>
                      <b-th>Price List (Rp)</b-th>
                      <b-th>Diskon Standart (%)</b-th>
                      <b-th>Harga Jual Standart</b-th>
                      <b-th>Harga Jual diminta</b-th>
                      <b-th>Selisih Harga</b-th>
                      <b-th>Ket Khusus</b-th>
                      <b-th>Tujuan Kirim</b-th>
                      <b-th>Franco</b-th>
                      <b-th>Ket Franco</b-th>
                      <b-th>Action</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(val,idx) in tbl_step2" :key="idx">
                      <b-td>{{ val.trn_dtl_line }}</b-td>
                      <b-td>{{ val.trn_dtl_no_acr_input }}</b-td>
                      <b-td>{{ vueNumberFormat(val.trn_dtl_sisa_kuota) }}</b-td>
                      <b-td>{{ val.trn_dtl_group_kode }}</b-td>
                      <b-td class="text-left">{{ val.trn_dtl_volume}}</b-td>
                      <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_pricelist, {}) }}</b-td>
                      <b-td>{{ val.trn_dtl_diskonstandart}}%</b-td>
                      <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_sellprice, {}) }}</b-td>
                      <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_sellprice_req, {}) }}</b-td>
                      <b-td class="text-right">{{ vueNumberFormat((val.trn_dtl_sellprice_req - val.trn_dtl_sellprice), {}) }}</b-td>
                      <b-td>{{ val.trn_dtl_desc }}</b-td>
                      <b-td>{{ val.trn_dtl_shipto }}</b-td>
                      <b-td>{{ val.trn_dtl_franco == 'gudang' ? 'Gudang MU' : 'Lainnya' }}</b-td>
                      <b-td>{{ val.trn_dtl_franco_desc }}</b-td>
                      <b-td class="text-center"><feather-icon icon="TrashIcon" @click="showMsgBoxDelete(val.trn_code, val.trn_dtl_line)"/></b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tfoot>
                  <b-tr>
                    <b-td class="text-center" colspan="4"> &nbsp;&nbsp;&nbsp;&nbsp;
                        Total
                      </b-td>
                      <b-td class="text-left">{{ tbl_sum_vol }}</b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td class="text-right">{{ vueNumberFormat(tbl_sum_jual_std, {}) }}</b-td>
                      <b-td class="text-right">{{ vueNumberFormat(tbl_sum_jual_req, {}) }}</b-td>
                      <b-td class="text-right">{{ vueNumberFormat(tbl_sum_harga_selisih, {}) }}</b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- step 3-->
          <tab-content
            title="Step 3"
            :before-change="validationFormSocial"
          >
            <validation-observer
              ref="step3"
              tag="form"
            >
              <b-row>
                <b-col md="2">
                  <h6> Jenis Input</h6>
                </b-col>
                <b-col md="2">
                  <h6> : {{ this.userDataInfo.trn_type.text }}</h6>
                </b-col>
                <b-col md="2">
                  <h6> Nama Proyek</h6>
                </b-col>
                <b-col md="6">
                  <h6> : {{ this.userDataInfo.trn_name_project }}</h6>
                </b-col>

                <b-col md="2">
                  <h6> Dasar Hitung</h6>
                </b-col>
                <b-col md="2">
                  <h6> : {{ this.userDataInfo.trn_dasarhitung.text }}</h6>
                </b-col>
                <b-col md="2">
                  <h6> Mitra Usaha</h6>
                </b-col>
                <b-col md="6">
                  <h6> : {{ this.userDataInfo.trn_mitrausaha.label }}</h6>
                </b-col>

                <b-col md="2">
                  <h6> Tanggal Pengajuan</h6>
                </b-col>
                <b-col md="2">
                  <h6> : {{ this.userDataInfo.trn_date_request }}</h6>
                </b-col>
                <b-col md="2">
                  <h6> Periode Delivery</h6>
                </b-col>
                <b-col md="6">
                  <h6> : {{ this.userDataInfo.trn_periode_start+" s/d "+this.userDataInfo.trn_periode_end }}</h6>
                </b-col>

                <b-col md="2">
                  <h6> Program DF</h6>
                </b-col>
                <b-col md="2">
                  <h6> : {{ this.userDataInfo.trn_program_df.text }}</h6>
                </b-col>
                <b-col md="2">
                  <h6> Premi</h6>
                </b-col>
                <b-col md="2">
                  <h6> : {{ this.userDataInfo.trn_premi.text }}</h6>
                </b-col>
                <b-col md="2">
                  <h6> </h6>
                </b-col>
                <b-col md="2">
                  <h6> </h6>
                </b-col>

                <b-col md="2">
                  <h6> Catatan</h6>
                </b-col>
                <b-col md="6">
                  <h6> : {{ this.userDataInfo.trn_notes}}</h6>
                </b-col>
                <b-col md="2">
                  <h6> </h6>
                </b-col>
                <b-col md="2">
                  <h6> </h6>
                </b-col>
                                
                <b-col md="2">
                  <h6> Nilai Proyek</h6>
                </b-col>
                <b-col md="2">
                  <h6> : Rp. {{ vueNumberFormat(this.tbl_sum_jual_req) }},-</h6>
                </b-col>
                <b-col md="2">
                  <h6> Diskon diminta (%)</h6>
                </b-col>
                <b-col md="2">
                  <h6> : {{ this.userDataInfo.trn_diskon_request }}%</h6>
                </b-col>                
                <b-col md="2">
                  <h6> Diskon diminta (Rp)</h6>
                </b-col>
                <b-col md="2">
                  <h6> : Rp. {{ vueNumberFormat(this.tbl_sum_harga_selisih) }},-</h6>
                </b-col>
                
                <b-col md="2">
                  <h6> <strong>Total Harga Jual</strong></h6>
                </b-col>
                <b-col md="2">
                  <h6> : <strong>Rp. {{ vueNumberFormat(this.tbl_sum_jual_req) }},-</strong></h6>
                </b-col>

                <br>
                <br>

                <b-table-simple
                  hover
                  caption-top
                  responsive
                  class="rounded-bottom mb-0"
                >
                  <b-thead head-variant="light">
                    <b-tr>
                      <b-th>No</b-th>
                      <b-th>No.ACR</b-th>
                      <b-th>Sisa Kuota</b-th>
                      <b-th>Nama Product</b-th>
                      <b-th>Volume (Ton)</b-th>
                      <b-th>Price List (Rp)</b-th>
                      <b-th>Diskon Standart (%)</b-th>
                      <b-th>Harga Jual Standart</b-th>
                      <b-th>Harga Jual diminta</b-th>
                      <b-th>Selisih Harga</b-th>
                      <b-th>Ket Khusus</b-th>
                      <b-th>Tujuan Kirim</b-th>
                      <b-th>Franco</b-th>
                      <b-th>Ket Franco</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(val,idx) in tbl_step2" :key="idx">
                      <b-td>{{ val.trn_dtl_line }}</b-td>
                      <b-td>{{ val.trn_dtl_no_acr_input }}</b-td>
                      <b-td>{{ val.trn_dtl_sisa_kuota }}</b-td>
                      <b-td>{{ val.trn_dtl_group_kode }}</b-td>
                      <b-td class="text-left">{{ val.trn_dtl_volume}}</b-td>
                      <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_pricelist, {}) }}</b-td>
                      <b-td>{{ val.trn_dtl_diskonstandart}}%</b-td>
                      <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_sellprice, {}) }}</b-td>
                      <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_sellprice_req, {}) }}</b-td>
                      <b-td class="text-right">{{ vueNumberFormat((val.trn_dtl_sellprice_req - val.trn_dtl_sellprice), {}) }}</b-td>
                      <b-td>{{ val.trn_dtl_desc }}</b-td>
                      <b-td>{{ val.trn_dtl_shipto }}</b-td>
                      <b-td>{{ val.trn_dtl_franco == 'gudang' ? 'Gudang MU' : 'Lainnya' }}</b-td>
                      <b-td>{{ val.trn_dtl_franco_desc }}</b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tfoot>
                  <b-tr>
                    <b-td class="text-center" colspan="4"> &nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>Total</strong>
                      </b-td>
                      <b-td class="text-left"><strong>{{ tbl_sum_vol }}</strong></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_jual_std, {}) }}</strong></b-td>
                      <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_jual_req, {}) }}</strong></b-td>
                      <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_harga_selisih, {}) }}</strong></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                      <b-td></b-td>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>
              </b-row>
            </validation-observer>
            <br>
            <br>
          </tab-content>
        </form-wizard>
      </div>
    </b-card>
    </b-overlay>
  </component>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BButton, BFormInvalidFeedback, BTable, 
  BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot, BOverlay
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import { onMounted, ref, getCurrentInstance } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  codeVueMultiBasic, codeVueMultiIcon, codeMultiPush, codeVueSelectionLimit, codeVueDir,
} from './code'
import { codeVariant } from './codetoast'
import { codeIcon } from './wizard'
import flatPickr from 'vue-flatpickr-component'
import { async } from 'q'
import Cleave from 'vue-cleave-component'
import router from '@/router'
// import { extend } from 'vee-validate';
// import * as rules from 'vee-validate/dist/rules';
// import { messages } from 'vee-validate/dist/locale/en.json';

export default {
  components: {
    BCard, 
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    flatPickr,
    BFormRadioGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    Cleave,
    BOverlay
  },
  directives: {
    Ripple,
  },
  data() {
     return {
      show: false,
      trn_code: localStorage.getItem('trn_code') == null ? "" : localStorage.getItem('trn_code'),
      trn_type: "",
      trn_bu: "",
      trn_name_project: "",
      trn_dasarhitung: "",
      trn_diskon_request: "",
      trn_date_request: "",
      trn_periode_start: "",
      trn_periode_end: "",
      trn_program_df: "",
      trn_program_df_desc: "",
      trn_premi: "",
      trn_premi_persen: "",
      trn_mitrausaha: "",
      trn_notes: "",
      trn_app_sales_user: "",
      trn_app_sales_user_lvl: "",
      trn_app_div_user: "",
      trn_app_div_user_lvl: "",
      trn_flag: "",
      app_status: "",
      // selectedJenisinput: '',
      // selectedDomain: '',
      // nama_proyek: '',
      // selectedDasarhitung: '',
      // diskon: '',
      // tgl_pengajuan: '',
      // start_date: '',
      // end_date: '',
      // selectedMitrausaha: '',
      // selectedProgramdF: '',
      // selectedPremi: '',trn_dtl_sellprice
      // presentase:'',
      // catatan: '',
      trn_dtl_line: '',
      trn_dtl_no_acr_input: '',
      trn_dtl_no_acr: '',
      trn_dtl_no_project: '',
      trn_dtl_sisa_kuota: '',
      trn_dtl_sisa_kuota_hide: 0,
      trn_dtl_group_kode: '',
      trn_dtl_volume: '',
      isDisabled: true,
      trn_dtl_pricelist: 0,
      trn_dtl_pricelist_hide: 0,
      isDisabledPL: true,
      trn_dtl_diskonstandart: '45',
      trn_dtl_sellprice: '',
      trn_dtl_sellprice_hide: 0,
      trn_dtl_sellprice_req: '',
      trn_dtl_sellprice_req_hide: 0,
      trn_dtl_selisih_harga: 0,
      trn_dtl_desc: '',
      trn_dtl_shipto: '',
      trn_dtl_franco: '',
      trn_dtl_franco_desc: '',
      trn_dtl_flag: '',
      // selectedNamaproduct: '',
      // selectedPf: '',
      selectedFranco: '',
      ketfranco: '',
      landMark: '',
      diskonstandar: '',
      hargajual: '',
      hargadiminta: '',
      selisihharga: '',
      required,
      email,
      codeIcon,
      dasarName: [
        { value: 'amount', text: 'Amount' },
        { value: 'tonase', text: 'Tonase' },
      ],
      premiName: [
        { value: 1, text: 'Ya' },
        { value: 0, text: 'Tidak' },
      ],
      pfName: [
        { value: 'pipa', text: 'Pipa' },
        { value: 'fitting', text: 'Fitting' },
      ],
      // tujuankirimName: [
      //   { value: 'agen', text: 'Agen' },
      //   { value: 'toko', text: 'Toko' },
      // ],
      francoName: [
        { value: 'gudang', text: 'Gudang MU' },
        { value: 'lainnya', text: 'Lainnya' },
      ],
      formatter:{
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        number_pl: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          onValueChanged: this.onValueChanged.bind(this),
        },
      },
      isRequiredVolume: false,
      isRequiredKeteranganFranco: true,
      isDisabledFranco: true,
      tbl_step2: [],
      tbl_sum_vol: 0,
      tbl_sum_jual_std: 0,
      tbl_sum_jual_req: 0,
      tbl_sum_harga_selisih: 0,
    }
  },
  created: function(){
    this.load_table_step2()
  },
  methods: {
    async onchangeBU(){
      // const selectedd = JSON.stringify(this.userDataInfo.trn_bu)
      const selectedd = this.userDataInfo.trn_bu
      // console.log(selectedd);
      const combo_div = []
      const combo_mu = []
      if(selectedd == null){
        this.trn_div_opt = []
        this.trn_mitrausaha_opt = []
        this.userDataInfo.trn_div = ''
        this.userDataInfo.trn_mitrausaha = ''
      }
      else{
        this.userDataInfo.trn_div = ''
        this.userDataInfo.trn_mitrausaha = ''

        const resultDiv = await axios.post(`${useAppConfig().AppUrl}global/listDivisiByBU`, {id_bu: JSON.stringify(selectedd)})
        let div = resultDiv.data[0].divisi_by_bu
        for (let i = 0, len = div.length; i < len; i++) {
          combo_div.push({ text: div[i].name_desc_div, value: div[i].div_name })
        }
        // this.$refs["trn_div_ref"].clearSelection()
        this.trn_div_opt = []
        this.trn_div_opt.push(combo_div)

        const result = await axios.post(`${useAppConfig().AppUrl}global/listMU`, {bu_id: selectedd.value})
        let mu = result.data[0].mu
        for (let i = 0, len = mu.length; i < len; i++) {
          // console.log(mu[i]);
          combo_mu.push({ label: mu[i].cust_code_name, value: mu[i].cust_code })
        }
        this.trn_mitrausaha_opt = []
        this.trn_mitrausaha_opt.push(combo_mu)
      }
      // const result2 = await axios.post(`${useAppConfig().AppUrl}global/listdivisibybu`,  { id_bu: selectedd })
    },
    async onchangeDiv(){
      const selectedBU = this.userDataInfo.trn_bu
      const selectedDiv = this.userDataInfo.trn_div
      // alert(selectedDiv)
      const combo_seg = []
      this.trn_seg_opt = []
      this.userDataInfo.trn_segmen = ''

      const resultSeg = await axios.post(`${useAppConfig().AppUrl}global/listSegmenByBU`, {id_bu: JSON.stringify(selectedBU), div_name: JSON.stringify(selectedDiv)})
      let seg = resultSeg.data[0].data
      for (let i = 0, len = seg.length; i < len; i++) {
        combo_seg.push({ text: seg[i].segmen_code_name, value: seg[i].segmen_code })
      }
      // this.$refs["trn_div_ref"].clearSelection()
      this.trn_segmen_opt = []
      this.trn_segmen_opt.push(combo_seg)
    },
    async onchangeMU(){
      const selectedd = this.userDataInfo.trn_mitrausaha //JSON.stringify(this.trn_mitrausaha)
      if(selectedd == null){
        console.log('MU not selected');
      }
      else{
        const result0 = await axios.post(`${useAppConfig().AppUrl}global/getDomain`, {id_bu: this.userDataInfo.trn_bu.value})
        let res0 = result0.data[0].data[0]
        // alert(res0.bu_domain);
        const result1 = await axios.post(`${useAppConfig().AppUrl}global/getWSA_Global`, {
          functions: "getDBCPriceListCS",
          params: {
            xdomain:res0.bu_domain, xcust:selectedd.value
          }
        })
        let lenRow = result1.data.tRow
        if(lenRow > 0){
          // console.log();
          let dataRow = result1.data.tt_pl.tt_plRow
          console.log(dataRow);
          let arrTampRow = []
          for (let i = 0; i < lenRow; i++) {
            // console.log(dataRow[i].xitemdesc); // DF & Price List FG
            // console.log(dataRow[i].xpricelist); // 10021, DF & HLSR
            if(dataRow[i].xpricelist == 'DF'){
              arrTampRow.push(true)
            }
            else{
              arrTampRow.push(false)
            }
          }
          // console.log(arrTampRow);
          let unique = arrTampRow.filter((value, index, array) => array.indexOf(value) === index);
          // console.log(unique);
          if(unique.length > 0){
            this.userDataInfo.trn_program_df = {text:"Tidak", value: "0"}
          }
          else{
            if(unique[0] == true){
              this.userDataInfo.trn_program_df = {text:"Ya", value: "1"}
            }
            else{
              this.userDataInfo.trn_program_df = {text:"Tidak", value: "0"}
            }
          }
        }
        else{
          this.userDataInfo.trn_program_df = {text:"Tidak", value: "0"}
        }
      }
    },
    async onchangePremi(){
      let selected = this.userDataInfo.trn_premi.value
      if(selected == 0){
        this.userDataInfo.trn_premi_persen = ""
      }
    },
    async changeNoACR(){
      let noACR = this.trn_dtl_no_acr_input
      const result0 = await axios.post(`${useAppConfig().AppUrl}global/getDomain`, {id_bu: this.userDataInfo.trn_bu.value})
      let res0 = result0.data[0].data[0]
      // alert(noACR)
      const result1 = await axios.post(`${useAppConfig().AppUrl}global/getWSA_Global`, {
        functions: "getDBCAcrProyek",
        params: {
          parDomain: res0.bu_domain, noAcr:noACR, stts: "c"
        }
      })
      let res1 = result1.data.listAcr
      // console.log(res1);
      if(res1 != undefined){
        // console.log(res1.listAcrRow[0].sisaKuotaOrder);
        this.trn_dtl_sisa_kuota = res1.listAcrRow[0].sisaKuotaOrder
        this.trn_dtl_sisa_kuota_hide = res1.listAcrRow[0].sisaKuotaOrder
      }
      else{
        console.log('Data No. ACR tidak ditemukan!');
        this.trn_dtl_sisa_kuota = ""
        this.trn_dtl_sisa_kuota_hide = 0
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data No. ACR tidak ditemukan!',
            variant: 'danger',
          },
        })
      }      
      // this.trn_dtl_sisa_kuota = 0
    },
    async onchangeKodeGroup(){
      let selected = this.trn_dtl_group_kode.value
      // alert(selected)
      this.show = true
      const result = await axios.post(`${useAppConfig().AppUrl}global/getCatProdByKodeGroup`, {bu_id: this.userDataInfo.trn_bu.value, cat_kode: selected})
      let res_data = result.data[0].data[0]
      // console.log(res_data[0]);
      if(res_data.cat_pf == 'Pipa'){
        const result1 = await axios.post(`${useAppConfig().AppUrl}global/getWSA_Global`, {
          functions: "getDBCAvgPriceProdln",
          params: {
            parDomain: res_data.bu_domain, prodLine: res_data.cat_prodline.split(" ").join("")
          }
        })
        const res_dataPL = result1.data.hargaProd.hargaProdRow[0].harga
        // console.log(result1.data.hargaProd.hargaProdRow[0].harga)
        this.trn_dtl_pricelist = res_dataPL
        this.trn_dtl_pricelist_hide = res_dataPL
        this.isDisabled = false
        this.isDisabledPL = false // true => dibuat false request by tiket no : 473-WDJR-2308 //
        this.isRequiredVolume = true
      }
      else{
        this.isDisabled = true
        this.isDisabledPL = false
        this.isRequiredVolume = false
      }
      this.show = false
    },
    changeVolume(){
      // alert(this.userDataInfo.trn_diskon_request)
      // alert(this.trn_dtl_pricelist_hide)
      let hargajualstd = (this.trn_dtl_volume * this.trn_dtl_pricelist_hide) * (1-(this.trn_dtl_diskonstandart/100))
      // alert(hargajualstd)
      this.trn_dtl_sellprice = hargajualstd
      this.trn_dtl_sellprice_hide = hargajualstd

      let hargajualreq = (this.trn_dtl_volume * this.trn_dtl_pricelist_hide) * (1-(this.userDataInfo.trn_diskon_request/100))
      this.trn_dtl_sellprice_req = hargajualreq
      this.trn_dtl_sellprice_req_hide = hargajualreq

      let selisih_harga = (hargajualreq - hargajualstd)
      this.trn_dtl_selisih_harga = selisih_harga
    },
    onValueChanged({ target }) {
      if((target.rawValue).charAt((target.rawValue).length - 1) != '.'){
        this.trn_dtl_pricelist_hide = target.rawValue
      }
      // else{
      //   this.trn_dtl_pricelist = this.trn_dtl_pricelist_hide
      // }
      // this.$nextTick(() => {
      //   this.currentValue = target.value;
      //   this.$emit('input', target.rawValue);
      // });
      let hargajualstd = (this.trn_dtl_volume * this.trn_dtl_pricelist_hide) * (1-(this.trn_dtl_diskonstandart/100))
      // alert(hargajualstd)
      this.trn_dtl_sellprice = hargajualstd
      this.trn_dtl_sellprice_hide = hargajualstd

      let hargajualreq = (this.trn_dtl_volume * this.trn_dtl_pricelist_hide) * (1-(this.userDataInfo.trn_diskon_request/100))
      this.trn_dtl_sellprice_req = hargajualreq
      this.trn_dtl_sellprice_req_hide = hargajualreq

      let selisih_harga = (hargajualreq - hargajualstd)
      this.trn_dtl_selisih_harga = selisih_harga
    },
    changeDiskonStd(){
      let hargajualstd = (this.trn_dtl_volume * this.trn_dtl_pricelist_hide) * (1-(this.trn_dtl_diskonstandart/100))
      // alert(hargajualstd)
      this.trn_dtl_sellprice = hargajualstd
      this.trn_dtl_sellprice_hide = hargajualstd

      let hargajualreq = (this.trn_dtl_volume * this.trn_dtl_pricelist_hide) * (1-(this.userDataInfo.trn_diskon_request/100))
      this.trn_dtl_sellprice_req = hargajualreq
      this.trn_dtl_sellprice_req_hide = hargajualreq

      let selisih_harga = (hargajualreq - hargajualstd)
      this.trn_dtl_selisih_harga = selisih_harga
    },
    onchangeFranco(){
      // alert(this.trn_dtl_franco);
      if(this.trn_dtl_franco == null){
        console.log(this.trn_dtl_franco);
        this.isRequiredKeteranganFranco = true
        this.trn_dtl_franco_desc = ""
        this.isDisabledFranco = true
      }
      else{
        let franco = this.trn_dtl_franco.value
        if(franco == 'gudang'){
          this.isRequiredKeteranganFranco = false
          this.isDisabledFranco = true
        }
        else{
          this.isRequiredKeteranganFranco = true
          this.trn_dtl_franco_desc = ""
          this.isDisabledFranco = false
        }
      }      
    },
    async formSubmitted() {
      // PROSES SEND APPROVAL //
      this.show = true
      const params = new URLSearchParams()
      // params.append('trn_code', this.userDataInfo.trn_code)
      params.append('trn_code', localStorage.getItem('trn_code') == null || localStorage.getItem('trn_code') == "" ? this.userDataInfo.trn_code : localStorage.getItem('trn_code'))
      params.append('trn_flag', '1')
      params.append('hist_type', 'Normal')
      // params.append('hist_approval', localStorage.getItem('emp_id'))
      params.append('hist_approval', '')
      params.append('hist_level', '0')
      params.append('hist_desc', 'Pengajuan')
      const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/send_approval`, params)
      console.log(result);

      if (result.data.message != 'Berhasil kirim data') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Data Gagal Dikirim. '+result.data.message,
            variant: 'danger',
          },
        })
        this.show = false
      } 
      else{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'EditIcon',
            text: 'Data Berhasil Dikirim',
            variant: 'success',
          },
        })
        this.$router.push('/apps/pengajuan-sales/list')
      }
    },
    validationForm() {
      // alert(this.trn_diskon_request)
      return new Promise((resolve, reject) => {
        this.$refs.step1.validate().then(async success => {
          if (success) {
            this.show = true
            const params = new URLSearchParams()
            params.append('param', 'add_header_pengajuan_sales')
            params.append('trn_code', localStorage.getItem('trn_code') == null || localStorage.getItem('trn_code') == "" ? this.userDataInfo.trn_code : localStorage.getItem('trn_code'))
            params.append('trn_type', JSON.stringify(this.userDataInfo.trn_type))
            params.append('trn_bu', JSON.stringify(this.userDataInfo.trn_bu))
            params.append('trn_div', JSON.stringify(this.userDataInfo.trn_div))
            params.append('trn_segmen', JSON.stringify(this.userDataInfo.trn_segmen))
            params.append('trn_name_project', this.userDataInfo.trn_name_project)
            params.append('trn_dasarhitung', JSON.stringify(this.userDataInfo.trn_dasarhitung))
            params.append('trn_diskon_request', this.userDataInfo.trn_diskon_request)
            params.append('trn_date_request', this.userDataInfo.trn_date_request)
            params.append('trn_periode_start', this.userDataInfo.trn_periode_start)
            params.append('trn_periode_end', this.userDataInfo.trn_periode_end)
            params.append('trn_program_df', JSON.stringify(this.userDataInfo.trn_program_df))
            params.append('trn_program_df_desc', this.userDataInfo.trn_program_df_desc)
            params.append('trn_premi', JSON.stringify(this.userDataInfo.trn_premi))
            params.append('trn_premi_persen', this.userDataInfo.trn_premi_persen)
            params.append('trn_mitrausaha', JSON.stringify(this.userDataInfo.trn_mitrausaha))
            params.append('trn_notes', this.userDataInfo.trn_notes)
            params.append('trn_flag', "0")
            params.append('created_by', localStorage.getItem('emp_id'))
            const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/add`, params)
            // console.log(result.data.message);

            if (result.data.message != '') {
              this.trn_code = result.data.code
              localStorage.setItem('trn_code', result.data.code)
              resolve(true)
              this.load_kode_group()
              this.load_ship_to()
              this.load_table_step2()
            }
            else{
              reject()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: 'Data Gagal Disimpan',
                  variant: 'danger',
                },
              })
            }
            this.show = false
          } else {
            reject()
          }
        })
      })
    },
    validationFormDetail() {
      return new Promise((resolve, reject) => {
        this.$refs.step2.validate().then(async success => {
          if (success) {
            this.show = true
            const params = new URLSearchParams()
            params.append('param', 'add_pengajuan_sales_step2')
            params.append('trn_code', localStorage.getItem('trn_code') == null || localStorage.getItem('trn_code') == "" ? this.userDataInfo.trn_code : localStorage.getItem('trn_code'))
            params.append('trn_dtl_no_acr_input', this.trn_dtl_no_acr_input)
            params.append('trn_dtl_sisa_kuota', this.trn_dtl_sisa_kuota_hide)
            params.append('trn_dtl_group_kode', JSON.stringify(this.trn_dtl_group_kode))
            params.append('trn_dtl_volume', this.trn_dtl_volume)
            params.append('trn_dtl_pricelist', this.trn_dtl_pricelist_hide)
            params.append('trn_dtl_diskonstandart', this.trn_dtl_diskonstandart)
            params.append('trn_dtl_sellprice', this.trn_dtl_sellprice_hide)
            params.append('trn_dtl_sellprice_req', this.trn_dtl_sellprice_req_hide)
            params.append('trn_dtl_desc', this.trn_dtl_desc)
            params.append('trn_dtl_shipto', JSON.stringify(this.trn_dtl_shipto))
            params.append('trn_dtl_franco', JSON.stringify(this.trn_dtl_franco))
            params.append('trn_dtl_franco_desc', this.trn_dtl_franco_desc)
            params.append('trn_dtl_flag', "0")
            params.append('created_by', localStorage.getItem('emp_id'))
            // console.log(params);
            // VALIDASI JIKA TYPE = SISA KUOTA => VALIDASI HARGA JUAL DIMINTA < SISA KUOTA //
            if(this.userDataInfo.trn_type.value == 'sisa_kuota'){
              // alert(trn_dtl_sellprice_req_hide+" ??? "+trn_dtl_sisa_kuota_hide)
              resolve(true)
              let sum_hjs = 0
              for (let i = 0; i < this.tbl_step2.length; i++) {
                const element = this.tbl_step2[i];
                sum_hjs += element.trn_dtl_sellprice_req
              }
              if((this.trn_dtl_sellprice_req_hide + sum_hjs) > this.trn_dtl_sisa_kuota_hide){
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    text: 'Informasi, harga jual tidak boleh lebih besar dari sisa kuota No. ACR : '+this.trn_dtl_no_acr_input,
                    variant: 'danger',
                  },
                })
              }
              else{
                const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/add_step2`, params)
                if (result.data.message == 'Berhasil menambah data') {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      text: 'Data Berhasil Disimpan',
                      variant: 'success',
                    },
                  })

                  // CLEAR FORM INPUT STEP 2 //
                  this.trn_dtl_no_acr_input = null
                  // this.$refs["drop_trn_dtl_group_kode"].clearSelection()
                  this.trn_dtl_group_kode = ''
                  this.trn_dtl_sisa_kuota = ""
                  this.trn_dtl_volume = null
                  this.trn_dtl_pricelist = null
                  this.trn_dtl_sellprice = null
                  this.trn_dtl_sellprice_req = null
                  this.trn_dtl_selisih_harga = 0
                  this.trn_dtl_desc = null
                  // this.$refs["drop_trn_dtl_shipto"].clearSelection()
                  this.trn_dtl_shipto = ''
                  // this.$refs["drop_trn_dtl_franco"].clearSelection()
                  this.trn_dtl_franco = ''
                  // this.trn_dtl_franco = [] //0
                  this.trn_dtl_franco_desc = ""
                  this.load_table_step2()
                }
                else{
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      text: 'Data Gagal Disimpan! '+result.data.message,
                      variant: 'danger',
                    },
                  })
                }
              }
            }
            else{
              const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/add_step2`, params)
              if (result.data.message == 'Berhasil menambah data') {
                resolve(true)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    text: 'Data Berhasil Disimpan',
                    variant: 'success',
                  },
                })

                // CLEAR FORM INPUT STEP 2 //
                this.trn_dtl_no_acr_input = null
                // this.$refs["drop_trn_dtl_group_kode"].clearSelection()
                this.trn_dtl_group_kode = ''
                this.trn_dtl_sisa_kuota = ""
                this.trn_dtl_volume = null
                this.trn_dtl_pricelist = null
                this.trn_dtl_sellprice = null
                this.trn_dtl_sellprice_req = null
                this.trn_dtl_selisih_harga = 0
                this.trn_dtl_desc = null
                // this.$refs["drop_trn_dtl_shipto"].clearSelection()
                this.trn_dtl_shipto = ''
                  // this.$refs["drop_trn_dtl_franco"].clearSelection()
                  this.trn_dtl_franco = ''
                // this.trn_dtl_franco = [] //0
                this.trn_dtl_franco_desc = ""
                this.load_table_step2()
              }
              else{
                resolve(true)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Notification',
                    icon: 'BellIcon',
                    text: 'Data Gagal Disimpan! '+result.data.message,
                    variant: 'danger',
                  },
                })
              }
            }
            this.show = false
    //         const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/add_step2`, params)
    //         // console.log(result.data.message);

    //         if (result.data.message == 'Berhasil menambah data') {
    // //         this.trn_code = result.data.message
    // //         localStorage.setItem('trn_code', result.data.message)
    //           resolve(true)
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Notification',
    //               icon: 'BellIcon',
    //               text: 'Data Berhasil Disimpan',
    //               variant: 'success',
    //             },
    //           })

    //           // CLEAR FORM INPUT STEP 2 //
    //           this.trn_dtl_no_acr_input = null
    //           this.$refs["drop_trn_dtl_group_kode"].clearSelection()
    //           this.trn_dtl_sisa_kuota = ""
    //           this.trn_dtl_volume = null
    //           this.trn_dtl_pricelist = null
    //           this.trn_dtl_sellprice = null
    //           this.trn_dtl_sellprice_req = null
    //           this.trn_dtl_selisih_harga = 0
    //           this.trn_dtl_desc = null
    //           this.$refs["drop_trn_dtl_shipto"].clearSelection()
    //           this.$refs["drop_trn_dtl_franco"].clearSelection()
    //           // this.trn_dtl_franco = [] //0
    //           this.trn_dtl_franco_desc = ""

    //           // RELOAD TABEL STEP 2 //
    //           // const result_table0 = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/tbl_step2`, {})
    //           // this.tbl_step2 = result_table0.data[0]
    //           // let tbls = result_table0.data[0]
    //           // let sum_vol = 0, sum_jual_std = 0, sum_jual_req = 0, sum_harga_selisih = 0
    //           // for (let i = 0, len = tbls.length; i < len; i++) {
    //           //   // console.log(tbls[i].trn_dtl_volume);
    //           //   sum_vol += tbls[i].trn_dtl_volume
    //           //   sum_jual_std += tbls[i].trn_dtl_sellprice
    //           //   sum_jual_req += tbls[i].trn_dtl_sellprice_req
    //           // }
    //           // sum_harga_selisih = (sum_jual_req - sum_jual_std)
    //           // this.tbl_sum_vol = sum_vol
    //           // this.tbl_sum_jual_std = sum_jual_std
    //           // this.tbl_sum_jual_req= sum_jual_req
    //           // this.tbl_sum_harga_selisih= sum_harga_selisih
    //           this.load_table_step2()
    //         }
    //         else{
    //           resolve(true)
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Notification',
    //               icon: 'BellIcon',
    //               text: 'Data Gagal Disimpan! '+result.data.message,
    //               variant: 'danger',
    //             },
    //           })
    //         }
          } else {
            reject()
          }
        })
      })
    },
    async showMsgBoxDelete(id, line) {
      this.boxTwo = id
      this.$bvModal
        .msgBoxConfirm('Apakah yakin ingin menghapus data ini?', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          // alert(value)
          this.boxTwo = value
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('trn_code', id)
            params.append('trn_dtl_line', line)

            const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/delete`, params)
            if (result.data.message === 'Berhasil menghapus data') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.load_table_step2()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Gagal menghapus data!',
                  variant: 'danger',
                },
              })
            }
          }
        })
    },
    validationFormInfo() {
      console.log(this.tbl_step2)
      return new Promise((resolve, reject) => {
        if(this.tbl_step2.length > 0){
          resolve(true)          
        }
        else{
          reject()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'BellIcon',
              text: 'Isi tabel data step 2 lebih dahulu!',
              variant: 'danger',
            },
          })
        }
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.step3.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async load_table_step2() {
      const result_table0 = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/tbl_step2`, {trn_code: localStorage.getItem('trn_code')})
      console.log(result_table0.data[0][1]);
      // let tbl_stp2 = JSON.stringify(result_table0[0])

      this.tbl_step2 = result_table0.data[0]
      let tbls = result_table0.data[0]
      let sum_vol = 0, sum_jual_std = 0, sum_jual_req = 0, sum_harga_selisih = 0
      for (let i = 0, len = tbls.length; i < len; i++) {
        // console.log(tbls[i].trn_dtl_volume);
        sum_vol += tbls[i].trn_dtl_volume
        sum_jual_std += tbls[i].trn_dtl_sellprice
        sum_jual_req += tbls[i].trn_dtl_sellprice_req
        // combo_mu.push({ label: mu[i].cust_code_name, value: mu[i].cust_code })
      }
      sum_harga_selisih = (sum_jual_req - sum_jual_std)

      this.tbl_sum_vol = sum_vol
      this.tbl_sum_jual_std = sum_jual_std
      this.tbl_sum_jual_req= sum_jual_req
      this.tbl_sum_harga_selisih= sum_harga_selisih
      // console.log(sum_vol);
    },
    async load_kode_group (){
      const combo_kode_group = []
      const result0 = await axios.post(`${useAppConfig().AppUrl}global/listKodeGroup`, {bu_id: this.userDataInfo.trn_bu.value})
      let kode_group = result0.data[0].data
      for (let i = 0, len = kode_group.length; i < len; i++) {
        // console.log(mu[i]);
        combo_kode_group.push({ text: kode_group[i].cat_kode, value: kode_group[i].cat_kode })
      }
      // this.trn_dtl_group_kode_opt.value = combo_kode_group
      this.trn_dtl_group_kode_opt = []
      this.trn_dtl_group_kode_opt.push(combo_kode_group)
    },
    async load_ship_to (){
      const combo_shipto = []
      const result2 = await axios.post(`${useAppConfig().AppUrl}global/listShiptoByMU`, {bu_id: this.userDataInfo.trn_bu.value, cust_code: this.userDataInfo.trn_mitrausaha.value})
      let res_data2 = result2.data[0].data
      for (let i = 0, len = res_data2.length; i < len; i++) {
        console.log(res_data2[i]);
        combo_shipto.push({ text: "["+res_data2[i].ad_addr+"] - "+res_data2[i].ad_name, value: res_data2[i].ad_addr })
      }
      this.trn_dtl_shipto_opt = []
      this.trn_dtl_shipto_opt.push(combo_shipto)
    }
  },
  setup() {
    const session = ref([])
    onMounted(async () => {
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const userDataInfo = ref({
      trn_code: "",
      trn_type: "",
      trn_bu: "",
      trn_name_project: "",
      trn_dasarhitung: "",
      trn_diskon_request: "",
      trn_date_request: "",
      trn_periode_start: "",
      trn_periode_end: "",
      trn_program_df: "",
      trn_program_df_desc: "",
      trn_premi: "",
      trn_premi_persen: "",
      trn_mitrausaha: "",
      trn_notes: "",
      trn_app_sales_user: "",
      trn_app_sales_user_lvl: "",
      trn_app_div_user: "",
      trn_app_div_user_lvl: "",
      trn_flag: "",
      app_status: "",
    })

    // STEP 1 //
    const trn_type_opt = ref([])
    const trn_bu_opt = ref([])
    const trn_div_opt = ref([])
    const trn_segmen_opt = ref([])
    const trn_mitrausaha_opt = ref([])
    const trn_program_df_opt = ref([])

    // STEP 2 //
    const trn_dtl_group_kode_opt = ref([])
    const trn_dtl_shipto_opt = ref([])

    // STEP 1 //
    onMounted(async() => {
      const combo_type = []
      const combo_bu = []

      combo_type.push({ text: "Baru", value: "baru"}, {text: "Sisa Kuota", value: "sisa_kuota" })
      trn_type_opt.value = combo_type

      const result = await axios.post(`${useAppConfig().AppUrl}global/listBUByMappingUser`, {'emp_id': localStorage.getItem('emp_id')})
      // console.log(result.data[0].listBUByMappingUser);
      let bu = result.data[0].listBUByMappingUser
      for (let i = 0, len = bu.length; i < len; i++) {
        // console.log(bu[i]);
        combo_bu.push({ text: bu[i].nama_bu, value: bu[i].id_bu })
      }
      trn_bu_opt.value = [combo_bu]
    })

    onMounted(async() => {
      const combo_program_df = []
      combo_program_df.push({ text: "Ya", value: "1" }, { text: "Tidak", value: "0" })
      trn_program_df_opt.value = combo_program_df

      // alert(localStorage.getItem('trn_code'))
      if(localStorage.getItem('trn_code') == null || localStorage.getItem('trn_code') == ""){
        console.log("Local Storage trn_code kosong")
      }
      else{
        // alert(localStorage.getItem('trn_code'))
        const paramsEdit = new URLSearchParams()
              paramsEdit.append('trn_code', localStorage.getItem('trn_code'))
        const result = await axios.get(`${useAppConfig().AppUrl}sales_pengajuan_sales/add_edit?${paramsEdit}`)
        userDataInfo.value = result.data[0]
        // console.log( userDataInfo.value)

        const combo_div = []
        const resultDiv = await axios.post(`${useAppConfig().AppUrl}global/listDivisiByBU`, {id_bu: JSON.stringify(userDataInfo.value.trn_bu)})
        let div = resultDiv.data[0].divisi_by_bu
        for (let i = 0, len = div.length; i < len; i++) {
          combo_div.push({ text: div[i].name_desc_div, value: div[i].div_name })
        }
        trn_div_opt.value = [combo_div]

        const combo_seg = []
        const resultSeg = await axios.post(`${useAppConfig().AppUrl}global/listSegmenByBU`, {id_bu: JSON.stringify(userDataInfo.value.trn_bu), div_name: JSON.stringify(userDataInfo.value.trn_div)})
        let seg = resultSeg.data[0].data
        for (let i = 0, len = seg.length; i < len; i++) {
          combo_seg.push({ text: seg[i].segmen_code_name, value: seg[i].segmen_code })
        }
        trn_segmen_opt.value = [combo_seg]

        const combo_mu = []
        const result1 = await axios.post(`${useAppConfig().AppUrl}global/listMU`, {bu_id: userDataInfo.value.trn_bu.value})
        let mu = result1.data[0].mu
        for (let i = 0, len = mu.length; i < len; i++) {
          // console.log(mu[i]);
          combo_mu.push({ label: mu[i].cust_code_name, value: mu[i].cust_code })
        }
        trn_mitrausaha_opt.value = [combo_mu]

        // STEP 2 //
        const combo_kode_group = []
        const result0 = await axios.post(`${useAppConfig().AppUrl}global/listKodeGroup`, {bu_id: userDataInfo.value.trn_bu.value})
        let kode_group = result0.data[0].data
        for (let i = 0, len = kode_group.length; i < len; i++) {
          // console.log(mu[i]);
          combo_kode_group.push({ text: kode_group[i].cat_kode, value: kode_group[i].cat_kode })
        }
        trn_dtl_group_kode_opt.value = combo_kode_group

        const combo_shipto = []
        const result2 = await axios.post(`${useAppConfig().AppUrl}global/listShiptoByMU`, {bu_id: userDataInfo.value.trn_bu.value, cust_code: userDataInfo.value.trn_mitrausaha.value})
        let res_data2 = result2.data[0].data
        for (let i = 0, len = res_data2.length; i < len; i++) {
          console.log(res_data2[i]);
          combo_shipto.push({ text: "["+res_data2[i].ad_addr+"] - "+res_data2[i].ad_name, value: res_data2[i].ad_addr })
        }
        trn_dtl_shipto_opt.value = combo_shipto
      }
    })

    return {
      session,
      codeVueMultiBasic,
      codeVueMultiIcon,
      codeMultiPush,
      codeVueSelectionLimit,
      codeVueDir,
      codeVariant,
      userDataInfo,
      trn_type_opt,
      trn_bu_opt,
      trn_div_opt,
      trn_segmen_opt,
      trn_mitrausaha_opt,
      trn_program_df_opt,
      // STEP 2 //
      trn_dtl_group_kode_opt,
      trn_dtl_shipto_opt,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

